// TODO : To hide the categories without leaf nodes, gide all with CSS and use jQuery children element.length to decide if they should be visible or not. Don't try and liomit the category results in sql!
// TODO : Need to ensure scores are updated correctly after search using the data values which appear o be OK ATM!
var intSelectedHarvestProjectID = null;
var intSelectedHarvestTaskID = null;
var intSelectedTacticID = null;
var intDecimalDuration = 0; 
var strHTMLPrompt = '';
var intSectorSearchSelectedSectorID = null;
var blnTacticsExpanded = false;
var startTime = new Date;
var intSecondsCounter = 0;
var timerInterval;
var timerArr = [];

$(document).ready( function() {
  
  if($('body').data('controller') != 'logger') { return; }
  
  // Image path changes
  strHTMLPrompt = $('#selected-harvest-client').html();

  // Filter
  $('#project_name').on('input', function(e) {
    $("#harvest-task-menu li.top-level").hide();
    $("#harvest-task-menu li.top-level > a:containsi('" + $('#project_name').val() + "')").parent().show();
  });
  $('#search-harvest-reset').on('click', function(e) {
    $('#project_name').val('');
    $("#harvest-task-menu li.top-level > a").parent().show();
  });

  $('#tactic_name_search').on('input', function(e) {

    // Only if they've typed 4 chars or more
    if(this.value.length > 3) {
      submit_search(); 
    }
  });

  $('#search-tactics-detail').on('click', function(e) { 
    if ( $('#search-container').is( ":hidden" ) ) {
      $('#search-container').slideDown( "slow" );
    } else {
      $('#search-container').slideUp( "slow" );
    }
  });

  $('#expand_tactic_desc').on('click', function(e) { 
    $('#selected-tactic-description').addClass('desc-value-visible');
    $('#selected-tactic-description').removeClass('desc-value-hide');
    $('#hide_tactic_desc').show();
    $('#expand_tactic_desc').hide();
    return false;
  } );
  
  $('#hide_tactic_desc').on('click', function(e) { 
    $('#selected-tactic-description').addClass('desc-value-hide');
    $('#selected-tactic-description').removeClass('desc-value-visible');
    $('#hide_tactic_desc').hide();
    $('#expand_tactic_desc').show();
    return false;
  }  );

  // Filter management. We're using a remove search now
  // $('#tactic_name_search').on('input', function(e) { 
  //   $("#tactics-menu li[data-category-level=1]").hide();
  //   $("#tactics-menu li[data-category-level=1] > a:containsi('" + $('#tactic_name_search').val() + "')").parent().show();
  // });

  $('#harvest-task-menu').metisMenu( {toggle: false });
  $('#tactics-menu').metisMenu( {toggle: false });

  $('a.harvest-task').on('click', function(e) {
    
    $('a.harvest-task').removeClass('selected');
    $(this).addClass('selected');
    intSelectedHarvestProjectID = $(this).data('project-id');
    intSelectedHarvestTaskID = $(this).data('task-id');
    $('#selected-harvest-client').html($(this).data('client-name'));
    $('#selected-harvest-project').html($(this).data('project-name'));
    $('#selected-harvest-task').html($(this).find('span.harvest-task-name').text());
    toggle_harvest_submit_option();
  });

  setup_tactic_events();

  // Setup contect menu
  $('a.collapse_tree').hide();
  gen_tc_context_menu();
  $("li.tc-category:not(:has(li.tc-tactic))").hide();

  $('#harvest-selection-submit').off("click");
  $('#harvest-selection-submit').click(create_time_entry);

  $('#selection-clear').off("click");
  $('#selection-clear').click(reset_selections);  

  $('#entry-hours').on('change', function(e) { 
    toggle_harvest_submit_option();
    set_decimal_duration();
    stopTimer();
  });

  $('button.add-minutes').on('click', function(e) {
    intDecimalDuration = parseFloat($(this).data('deciamlAdd'));
    //intDecimalDuration += parseFloat($(this).data('deciamlAdd')); 
    $('#entry-hours').val(time_convert(intDecimalDuration));
    toggle_harvest_submit_option();
    stopTimer();
  });

  // Tiny sector list now
  // $("#logger_sector_search").autocomplete({
  //   source: function(request, response) {
  //     $.ajax({
  //         url: "/projects/autocomplete_sector",
  //         dataType: "json",
  //         data: {
  //           term: "%" + $('#logger_sector_search').val() + "%"
  //         },
  //         success: function( data ) {
  //           response( data );
  //         },
  //         error: function(result,status,xhr) {
  //           toastr.error(xhr);
  //         }
  //       });
  //   },
  //   minLength: 2,
  //   delay:1000,
  //   select: function( event, ui ) {

  //     // Selection made so enable the option to add
  //     intSectorSearchSelectedSectorID  = ui.item.id;
  //     $("#logger_sector_search").val(ui.item.label); 
  //   }     
  // });

  $('button#start').on('click', function(e) {
    startTimer();
  });

  $('button#stop').on('click', function(e) {
    stopTimer();
  });
  
  $('button#reset').on('click', function(e) {
    intSecondsCounter = -1;
    updateTimer();
  });

  $("#search-submit").on('click', function(e) {
    submit_search();
  });  

  $('body').on('keypress',function(e){
   var key = (e.keyCode || e.which);
      if(key == 13 || key == 3) {
        submit_search();
      }
  });

  $("#search-tactics-reset").on('click', function(e) {
    reset_search_form();
    submit_search();
  });

  $('#harvest-task-menu a').on('click', function(e) {
    submit_search();
  });

});

function set_decimal_duration() {
  var strHours = $('#entry-hours').val() + '';
  if(strHours.indexOf(":") >= 0) {
    intDecimalDuration = timeToDecimal($('#entry-hours').val() );  
  } 
  if(strHours.indexOf(".") >= 0) {
    intDecimalDuration = $('#entry-hours').val();
  }
}
function reset_search_form() {
  $('#tactic_name_search').val('');
  $('#logger_sector_search').val('');
  $('#search_size').val('');
  $("input#selected-tactic-score").rating('update', null);
  $('p.strategy-checkbox input').prop( "checked", false );
}

function submit_search() {
  $('#search_selected_harvest_project_id').val(intSelectedHarvestProjectID);
  $('#search_sector_search_selected_sector_id').val(intSectorSearchSelectedSectorID);
  $('#search_rating').val($('#selected-tactic-score').val());
  var tmp = $('#search_form').serialize();
  //dataType: "json",
  $.ajax({
    url: "/logger/search",
    data: {
      search_form: tmp 
    },
    success: function( data ) {
      $("ul#tactics-menu").metisMenu('dispose');
      $('li.tc-category[data-category-level=1]').remove();
      $(data).insertAfter('ul#tactics-menu.tactic-categories li.sidebar-search');
      $('#search-container').slideUp( "slow" );
      $('ul#tactics-menu ul.tactic-categories-sub-menu, ul#tactics-menu ul.tactics-sub-menu').removeClass('mm-collapse');
      $('ul#tactics-menu ul.tactic-categories-sub-menu, ul#tactics-menu ul.tactics-sub-menu').addClass('mm-show');
      blnTacticsExpanded = true;
      $('ul#tactics-menu').metisMenu();
      $(".tactic-score").rating();
      setup_tactic_events();
      gen_tc_context_menu();
      $("li.tc-category:not(:has(li.tc-tactic))").hide();
    },
    error: function(result,status,xhr) {
      toastr.error(xhr);
    }
  });
}

function setup_tactic_events() {
  $('a.tactic').on('click', function(e) { 
    $('a.tactic').removeClass('selected');
    $(this).addClass('selected');
    intSelectedTacticID = $(this).parent('li').data('tactic-id');
    $('span#selected-tactic-category').html(
      $('li.tc-category[data-this-category-id="' + $(this).parent('li').data('this-category-id') + '"]').find('a.tactic_category').first().text()
    );
    $('span#selected-tactic').html($(this).find('span.tactic_name').text());
    $('span#selected-tactic-strategies').html($(this).find('span.tatcic-strategies').html());
    
    $('span#selected-tactic-description').html( ( $(this).data('description') == '' ? 'N/A' : $(this).data('description')) );
    $('span#selected-tactic-description').attr('title', ( $(this).data('description') == '' ? 'N/A' : $(this).data('description')) );
    var strURL = $(this).data('url');
    $('span#selected-tactic-url').html( (strURL == '' ? 'N/A': '<a href="' + strURL + '">' + strURL + '</a>') );
    $("input#selected-tactic-score").rating('update', $(this).data('avg-rating'));

    toggle_harvest_submit_option();
  });
}
function toggle_harvest_submit_option() {
  var strHours = $('#entry-hours').val() + '';
  if( (!intSelectedTacticID || !intSelectedHarvestTaskID || !intSelectedTacticID) || ( (strHours.indexOf(":") == -1) && strHours.indexOf(".") == -1) )  {
    $("button#harvest-selection-submit").prop('disabled', true); 
  } else {
    $("button#harvest-selection-submit").prop('disabled', false);
  }

  // Disable auto start : Req. by Dan
  // if( (!intSelectedTacticID || !intSelectedHarvestTaskID || !intSelectedTacticID) ) {
  // } else {
  //   startTimer();
  // }
}

function create_time_entry() {

  // Disable conf. req. by Dan
  // $('#confirm-dialog').modal('show');
  // $('.button-yes').unbind("click");
  // $('.button-yes').click(function () {

  set_decimal_duration();

  // Grab the current date for our simple decimal hour submission
  let date = new Date();
  var strNotes = $('#selected-tactic').text() + ' > ' + $('#entry-notes').val();
  $.ajax({
      url: "/create_time_entry",
      type: 'post',
      data: {
        project_id: intSelectedHarvestProjectID,
        task_id: intSelectedHarvestTaskID,
        tactic_id: intSelectedTacticID,
        spent_date: date.toISOString(),
        hours: intDecimalDuration,
        notes: strNotes
      }
  })
  .done(function(data) {
    toastr.success(data.success);
    collapse_tree();
    reset_selections();
  })
  .fail(function (xhr, textStatus, thrownError) {
    try {
      var obj = jQuery.parseJSON(xhr.responseText);
      toastr.error("An error was encountered : " + obj.error);
    } catch(err){
      toastr.error("An error was encountered : " + xhr.responseText);
    }
  });
  $('#confirm-dialog').modal('hide');
  
  // }); 
  // $('.button-no').click(function() {
  //   $('#confirm-dialog').modal('hide');
  // });
  return false;
}

function reset_selections(){
  stopTimer();
  intSelectedHarvestProjectID = null;
  intSelectedHarvestTaskID = null;
  intSelectedTacticID = null;
  intDecimalDuration = 0;

  $('#selected-harvest-client, #selected-harvest-project, #selected-harvest-task, #selected-tactic-category, #selected-tactic, #selected-tactic-strategies, #selected-tactic-description, #selected-tactic-url').html(strHTMLPrompt);
  $("input#selected-tactic-score").rating('update', 0);
  $('#entry-notes').val('');
  $('#entry-hours').val(time_convert(intDecimalDuration));

  $('a.harvest-task').removeClass('selected');
  $('a.tactic').removeClass('selected');
  $("#harvest-task-menu").metisMenu('dispose');
  $('#harvest-task-menu').metisMenu( {toggle: false });
  $("#tactics-menu").metisMenu('dispose');
  $('#tactics-menu').metisMenu( {toggle: false });
  $("button#harvest-selection-submit").prop('disabled', true); 
  intSectorSearchSelectedSectorID = null;
}

 

function timeToDecimal(t) {
  return t.split(':')
          .map(function(val) { return parseInt(val, 10); } )
          .reduce( function(previousValue, currentValue, index, array){
              return previousValue + currentValue / Math.pow(60, index);
  });
}

function decimalToTime(decimalTimeString) {
  var n = new Date(0,0);
  n.setSeconds(+decimalTimeString * 60 * 60);
  n.setMinutes(+decimalTimeString * 60); 
  return n.toTimeString().slice(0, 5);
}

function time_convert(decimalTimeString) { 
  var decimalTime = parseFloat(decimalTimeString);
  decimalTime = decimalTime * 60 * 60;
  var hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);
  var minutes = Math.floor((decimalTime / 60));
  decimalTime = decimalTime - (minutes * 60);
  var seconds = Math.round(decimalTime);
  if(hours < 10)
  {
      hours = "0" + hours;
  }
  if(minutes < 10)
  {
      minutes = "0" + minutes;
  }
  if(seconds < 10)
  {
      seconds = "0" + seconds;
  }
  return "" + hours + ":" + minutes;
}

function gen_tc_context_menu() {
  $('ul#tactics-menu li.tc-category, ul#tactics-menu li.tc-tactic').on('contextmenu', function(e) {
    var top = e.pageY - 10;
    var left = e.pageX - 60;
    $(".context-menu").removeClass("show").hide();
    $("#context-menu-tactics").css({
      display: "block",
      top: top,
      left: left
    }).addClass("show");

    // blocks default Webbrowser right click menu
    return false; 
  }).on("click", function() {
    $("#context-menu-tactics").removeClass("show").hide();
  });

  // And the click
  $("#context-menu-tactics a").on("click", function() {
    switch($(this).data('button-type')) {
      case 'expand_tree' :
        expand_tree();
        break;

      case 'collapse_tree' :
        collapse_tree();
        break;
    }
    $(".context-menu").hide();
  });
}

function expand_tree(){
  $('ul#tactics-menu ul.tactic-categories-sub-menu, ul#tactics-menu ul.tactics-sub-menu').removeClass('mm-collapse');
  $('ul#tactics-menu ul.tactic-categories-sub-menu, ul#tactics-menu ul.tactics-sub-menu').addClass('mm-show');
  $('a.expand_tree').hide();
  $('a.collapse_tree').show();
  blnTacticsExpanded = true;
}

function collapse_tree(){
  $('ul#tactics-menu ul.tactic-categories-sub-menu, ul#tactics-menu ul.tactics-sub-menu').addClass('mm-collapse');
  $('ul#tactics-menu ul.tactic-categories-sub-menu, ul#tactics-menu ul.tactics-sub-menu').removeClass('mm-show');
  $('a.expand_tree').show();
  $('a.collapse_tree').hide();
  blnTacticsExpanded = false;
}

function stopTimer() {
  clearInterval(timerInterval);
}

function startTimer() {
  clearInterval(timerInterval);
  timerInterval = setInterval(updateTimer, 1000);
  $("button#harvest-selection-submit").prop('disabled', false); 
}

function updateTimer(){   
  intSecondsCounter = intSecondsCounter + 1;
  var h = Math.floor(intSecondsCounter / 3600);
  var m = Math.floor(intSecondsCounter % 3600 / 60);
  var s = Math.floor(intSecondsCounter % 3600 % 60);
  if(h<10){
   h = "0"+h;
  }
  if(m<10){
   m = "0"+m;
  }
  if(s<10){
   s = "0"+s;
  }
  $("input#entry-hours").val(h+":"+m+":"+s);
}

function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay; 
}