var projectTable; 

$(document).ready( function() { 
  
  if($('body').data('controller') != 'projects') { return; }

  $('a#client_synch').on('click', function(e) { 
    client_synch();
  });

  var projectTable = draw_dataTable("#clients_projects_datatable"); // .order( [ 8, 'desc' ] );
  //var projectTable = $("#clients_projects_datatable").DataTable();
   
  $('#search_filter_include_inactive').click(function() {
    window.location.href = '/projects?search_filter_include_inactive=' + $('#search_filter_include_inactive').is(':checked');
  }); 

});

function client_synch() {
  $('#confirm-dialog').modal('show');
  $('.button-yes').unbind("click");
  $('.button-yes').click(function () {

    $.ajax({
      url: '/client_synch', 
      type: 'post', 
      success: function(data) {
        toastr.success("Synchronisation successfull");
      },
      error: function(xhr, status, error) {
        toastr.error("An exception was thrown during synchronisation : " + xhr.responseText);
        
        // 401: unaurthorised?
        if (xhr.status == "401") {

          // Give them 5 secs to see the message then redirect
          setTimeout(function() {
              window.location.replace("/users/sign_in");
          }, 5000);   
        }
      } // End error
    }); // End ajax

    $('#confirm-dialog').modal('hide');
  }); 
  $('.button-no').click(function() {
    $('#confirm-dialog').modal('hide');
  });
  return false;
}
