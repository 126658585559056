/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// SAH : listof yarn added modules
// yarn add  sass-loader sass popperjs/core  bootstrap-icons bootstrap@5.0.0-beta1 datatables jquery toastr 
// yarn add resolve-url-loader     // and see ref in config.webpacker/environment.rb    // so noe module css can load their own relative images
//TODO: datetimepicker / moment / popper


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// IMAGES
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("@popperjs/core")


var $ = require("jquery")

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;


// JavaScript
import bootstrap from "bootstrap";
import datatables from "datatables.net";

// import toastr from "toastr";
// global.toastr = toastr;
// window.toastr = toastr;  // window not required for taostr
//  OR instad of above 3 lines use>     global.toastr = require("toastr")
global.toastr = require("toastr")

// import('javascripts/application');
let webpackContext = require.context('../javascripts', true, /\.js$/)
for(let key of webpackContext.keys()) { webpackContext(key) }


// Extension import
var moment = require('moment')
window.moment = moment
//require("timecircles")
//require("jquery-datetimepicker")
require("metismenu");
//require("jquery-contextmenu");



// Stylesheets
// import('../stylesheets/application');  // don't need this because application.scss is a pack in its own right now
//require.context('../stylesheets', true, /\.(?:sass|scss)$/)

