var projectsTacticRatingsTable;

$(document).ready( function() { 
  
  if($('body').data('controller') != 'projects_tactic_ratings') { return; }

  $('input.te-tactic-rating').on('rating:change', function(event, value, caption) {

    console.log(value);
    console.log(caption);

    $.ajax({
      url: '/set_rating', 
      type: 'post', 
      data: {
        hvst_project_id: $(this).data("hvstProjectId"), 
        tactic_id: $(this).data("tacticId"), 
        rating: value
      },
      success: function(data) {
        toastr.success("Performance rating set to " + value + " and averages recalculated successfully.");
      },
      error: function(xhr, status, error) {
        toastr.error("An exception was thrown during update : " + xhr.responseText);
        
        // 401: unaurthorised?
        if (xhr.status == "401") {

          // Give them 5 secs to see the message then redirect
          setTimeout(function() {
              window.location.replace("/users/sign_in");
          }, 5000);   
        }
      } // End error
    }); // End ajax
  });

  var projectsTacticRatingsTable = draw_dataTable("#projects_tactic_ratings_datatable"); // .order( [ 8, 'desc' ] );
    
});

