

$(document).ready(function() {

  //setup_tooltips();
  
  // register date format with moment for datatable sorting
  $.fn.dataTable.moment( 'DD/MM/YYYY' );
  $.fn.dataTable.moment( 'DD/MM/YYYY HH:mm' );
  
  draw_dataTable('#admin_lookup_datatable', true);

  // Add containsi
  $.extend($.expr[':'], {
    'containsi': function(elem, i, match, array)
    {
      return (elem.textContent || elem.innerText || '').toLowerCase()
      .indexOf((match[3] || "").toLowerCase()) >= 0;
    }
  });

  jQuery.fn.exists = function(){ return this.length > 0; }

  

});

$(document).on('click', '#select_all', function(){
  $('td input:checkbox').prop( "checked", $(this).is(':checked') );
});
  
// window.setup_tooltips = function(){
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl, { boundary: 'window'})
//   });
// }


window.draw_dataTable =  function(dTable, with_search) {
  with_search = typeof with_search !== 'undefined' ? with_search : true;
  $.fn.dataTable.moment( 'DD/MM/YYYY' );
  $.fn.dataTable.moment( 'DD/MM/YYYY HH:mm' );
  if (with_search) {
    oTable = $(dTable).DataTable({   // returns DataTables instance rather than dataTable which is jquery object
      paging: false,
      filter: with_search,
      autoWidth: true,
      stateSave: true, 
      columnDefs: [   { targets: 'no-sort', orderable: false },
                      { targets: 'checkbox-sort', orderDataType: "dom-checkbox", type: 'numeric' },
                      { targets: 'input-char-sort', orderDataType: "dom-text" },
                      { targets: 'input-numeric-sort', orderDataType: "dom-text", type: 'numeric' }
                   ] ,
      dom: 'Bfrtip',      // https://datatables.net/reference/option/dom             
      buttons: [
              {
                  text: '<i title= "Clear search" class="fas fa-fw icon-gradient fa-remove"></i>',
                  action: function ( e, dt, node, config ) {
                    dt.search('').draw();
                  }
              }]
    });
  } else {
    oTable = $(dTable).DataTable({   // returns DataTables instance rather than dataTable which is jquery object
      paging: false,
      filter: with_search,
      autoWidth: true,
      stateSave: true, 
      columnDefs: [   { targets: 'no-sort', orderable: false },
                      { targets: 'checkbox-sort', orderDataType: "dom-checkbox", type: 'numeric' },
                      { targets: 'input-char-sort', orderDataType: "dom-text" },
                      { targets: 'input-numeric-sort', orderDataType: "dom-text", type: 'numeric' }
                   ]  
    });
  
  }
  return oTable;
}

// Ensure dataTable sorts on checked state of checkboxes & checkbox icons. or use using data { sort: ''}} on td instead
$.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col )
  {
    return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
        return  ( $('input', td).prop('checked') ? '1' : '0' );
    } );
};
$.fn.dataTable.ext.order['dom-text'] = function  ( settings, col )
{
    return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
        return $('input', td).val();
    } );
};


$(document).on('click', '#select_all', function(){
  $('td input:checkbox').prop( "checked", $(this).is(':checked') );
});

$(document).on('click', '.item_delete', function(){
  fnDeleteItemConfirmation($(this).data('item-type'), $(this).data('item-id'), $(this).data('dialog-title'), $(this).data('dialog-prompt') );
  return false;
});


window.fnDeleteItemConfirmation = function(item_type, item_id, title, prompt) {
  
  if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html(title) }
  if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html(prompt) }
  $('#delete-dialog').modal('show');
  
  // Disable previous binds & re-associate
  $('#button-yes').off("click");
  $('#button-yes').click(function () {
    fnDelItemConfirmed(item_type, item_id);
    $('#delete-dialog').modal('hide');
    if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html("Delete Confirmation") }
    if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html("Are you sure you want to delete this item?") }
  }); 
  $('.button-no').click(function() {
    $('#delete-dialog').modal('hide');
    if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html("Delete Confirmation") }
    if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html("Are you sure you want to delete this item?") }
  });
  
  return false;
}

window.fnDelItemConfirmed = function(item_type, item_id) {
  var jqxhr = $.ajax({url:'/' + item_type + 's/' + item_id, type: 'delete', dataType: 'script'});
  jqxhr.done(function(data) { 
    toastr.success("Item deleted successfully");
    $("tr[data-id='" + item_id + "'").remove();
  });

  jqxhr.fail(function(xhr, ajaxOptions, thrownError) { 

    // xhr is a XMLHttpRequest object.
    toastr.error("An exception was thrown deleting the row : " + xhr.responseText);    
  });
  return false;
}
