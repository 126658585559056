var timeEntryTable; 

$(document).ready( function() { 
  
  if($('body').data('controller') != 'time_entries') { return; }

  /*
  $('input.te-tactic-rating').on('rating:change', function(event, value, caption) {

    console.log(value);
    console.log(caption);

    $.ajax({
      url: '/set_rating_old', 
      type: 'post', 
      data: {id: $(this).data("te-id"), rating: value},
      success: function(data) {
        toastr.success("Performance rating set to " + value + " and averages recalculated successfully.");
      },
      error: function(xhr, status, error) {
        toastr.error("An exception was thrown during update : " + xhr.responseText);
        
        // 401: unaurthorised?
        if (xhr.status == "401") {

          // Give them 5 secs to see the message then redirect
          setTimeout(function() {
              window.location.replace("/users/sign_in");
          }, 5000);   
        }
      } // End error
    }); // End ajax
  });
  */
  var timeEntryTable = draw_dataTable("#time_entries_datatable"); // .order( [ 8, 'desc' ] );
    
});

