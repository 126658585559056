var intSelectedTacticCategoryID = null;
var intSelectedTacticID = null;

var blnCategoryNeedsAddingToMenu = false;
var blnTacticNeedsAddingToMenu = false;

$(document).ready( function() {
  
  if($('body').data('controller') != 'tactic_categories') { return; }
  
  $('#tactics-menu').metisMenu( {toggle: false });

  gen_tc_context_menu();

  // Filter management  
  $('#tactic_name_search').on('input', function(e) { 
    $("#tactics-menu li[data-category-level=1]").hide();
    $("#tactics-menu li[data-category-level=1] > a:containsi('" + $('#tactic_name_search').val() + "')").parent().show();
  });

  $(document).on('click', "button#new_category", function(event) {
    new_category(event);
  });
 
  $(document).on('click', "button#save_category", function(event) {
    save_category();
  });
 
  $(document).on('click', "button#new_tactic", function(event) {
    intSelectedTacticCategoryID = $(this).data("tactic-category-id");
    new_tactic(intSelectedTacticCategoryID);
  });

  $(document).on('click', "button#save_tactic", function(event) {
    save_tactic();
  });

});


function gen_tc_context_menu() {

  // Categories
  $('li.tc-category a.tactic_category').on('contextmenu', function(e) {
    var top = e.pageY - 10;
    var left = e.pageX - 60;
    $(".context-menu").removeClass("show").hide();
    $("#context-menu-category").css({
      display: "block",
      top: top,
      left: left
    }).addClass("show");

    $("#context-menu-category span.name").html($(this).find('span.category_name').text() + ' (CID: ' + $(this).parent('li').data('this-category-id') + ')');          
    $("#context-menu-category a").data('selected-id', $(this).parent('li').data('this-category-id') );  

    // blocks default Webbrowser right click menu
    return false; 
  }).on("click", function() {
    $("#context-menu-category").removeClass("show").hide();
  });

  // And the click
  $("#context-menu-category a").on("click", function() {
    $(this).parent().removeClass("show").hide();
    var intSelectedTacticCategoryID = $(this).data("selected-id");
    switch($(this).data('button-type')) {
      case 'new' :

        new_tactic_category(intSelectedTacticCategoryID);
        break;

      case 'edit' :

        $.ajax({
          url: '/tactic_categories/' + intSelectedTacticCategoryID + '/edit', 
          type: 'get', 
          dataType: 'script',
          success: function(data) {

            // No action just
          },
          error: function(xhr, status, error) {
            toastr.error("An exception was thrown : " + xhr.responseJSON);
            
            // 401: unaurthorised?
            if (xhr.status == "401") {

              // Give them 5 secs to see the message then redirect
              setTimeout(function() {
                  window.location.replace("/users/sign_in");
              }, 5000);   
            }
          } // End error
        }); // End ajax
        break;

      case 'delete' :

        $('#delete-dialog').modal('show');
                
        // Disable previous binds & re-associate
        $('#button-yes').off("click");
        $('#button-yes').click(function () {
          fnDeleteTacticCategoryConfirmed(intSelectedTacticCategoryID);
          $('#delete-dialog').modal('hide');
        }); 
        $('.button-no').click(function() {
          $('#delete-dialog').modal('hide');
        }); 
        break;

      case 'new_tactic' : 

        new_tactic(intSelectedTacticCategoryID);
        break;

    }
  });


  // Tactics
  $('li.tc-tactic a.tactic').on('contextmenu', function(e) {
    var top = e.pageY - 10;
    var left = e.pageX - 60;
    $(".context-menu").removeClass("show").hide();
    $("#context-menu-tactic").css({
      display: "block",
      top: top,
      left: left
    }).addClass("show");


    $("#context-menu-tactic span.name").html($(this).find('span.tactic_name').text() + ' (TID: ' + $(this).parent('li').data('tactic-id') + ')');  
    $("#context-menu-tactic a").data('tactic-category-id', $(this).parent('li').data('this-category-id') );  
    $("#context-menu-tactic a").data('tactic-id', $(this).parent('li').data('tactic-id') );  

    // blocks default Webbrowser right click menu
    return false; 
  }).on("click", function() {
    $("#context-menu-tactic").removeClass("show").hide();
  });

  // And the click
  $("#context-menu-tactic a").on("click", function() {
    $(this).parent().removeClass("show").hide();
    intSelectedTacticCategoryID = $(this).data("tactic-category-id");
    intSelectedTacticID = $(this).data("tactic-id");
    switch($(this).data('button-type')) {
      case 'new' :

        new_tactic_category(intSelectedTacticCategoryID);
        break;

      case 'edit' :

        $.ajax({
          url: '/tactics/' + intSelectedTacticID + '/edit', 
          type: 'get', 
          dataType: 'script',
          success: function(data) {
            // no actions

          },
          error: function(xhr, status, error) {
            toastr.error("An exception was thrown deleting the row : " + xhr.responseJSON);
            
            // 401: unaurthorised?
            if (xhr.status == "401") {

              // Give them 5 secs to see the message then redirect
              setTimeout(function() {
                  window.location.replace("/users/sign_in");
              }, 5000);   
            }
          } // End error
        }); // End ajax
        break;

      case 'delete' :

        $('#delete-dialog').modal('show');
                
        // Disable previous binds & re-associate
        $('#button-yes').off("click");
        $('#button-yes').click(function () {
          fnDeleteTacticConfirmed(intSelectedTacticID);
          $('#delete-dialog').modal('hide');
        }); 
        $('.button-no').click(function() {
          $('#delete-dialog').modal('hide');
        }); 
        break;

      case 'new_tactic' : 

        new_tactic(intSelectedTacticCategoryID);
        break;

    }
  });

}


function new_category(event) {
  intSelectedTacticCategoryID = $(event.currentTarget).data('this-category-id');
  $.ajax({
    url: '/tactic_categories/new', 
    type: 'get', 
    dataType: 'script',
    data: {
      tactic_category_id: intSelectedTacticCategoryID
    },
    success: function(data) {
      blnCategoryNeedsAddingToMenu = true;
    },
    error: function(xhr, status, error) {
      toastr.error("An exception was thrown deleting the row : " + xhr.responseJSON);
      
      // 401: unaurthorised?
      if (xhr.status == "401") {

        // Give them 5 secs to see the message then redirect
        setTimeout(function() {
            window.location.replace("/users/sign_in");
        }, 5000);   
      }
    } // End error
  }); // End ajax
}


function save_category(event) {
  $.ajax({
    url: $('#tactic_category_form').attr('action'), 
    type: $('#tactic_category_form').attr('method'), 
    data: $('#tactic_category_form').serialize(),
    success: function(data) {
      
      toastr.success("Item updated successfully");
      
      $("#dialog").modal('hide');

      // update the reference and name in out list
      if(blnCategoryNeedsAddingToMenu) {

        // Is this a top level or not as we need to know what level to attach to
        newItem = '<li class="tc-category mm-active" data-this-category-id="' + data.id + '">' +
                    '<a aria-expanded="true" href="#" class="tactic_category">' +
                      '<i class="bi bi-arrow-bar-right"></i>' +
                      '<span class="category_name">'  + data.name + '</span>' +
                    '</a>' +
                    '<ul class="tactic-categories-sub-menu mm-collapse mm-show" data-this-category-id="' + data.id + '"></ul>' +
                    '<ul class="tactics-sub-menu mm-collapse mm-show" data-this-category-id="' + data.id + '"></ul>' +
                  '</li>';
        if(data.main_category === false) {
          //$(newItem).insertBefore("li.tc-tactic-button[data-this-category-id='" + data.tactic_category_id + "']");
          $("li.tc-category[data-this-category-id='" + data.tactic_category_id + "'] ul.tactic-categories-sub-menu").first().append(newItem);
        }else {
          $('ul#tactics-menu').append(newItem);
        }
        blnCategoryNeedsAddingToMenu = false;
        
        // Refresh the menu to deal with our newly added item
        $("#tactics-menu").metisMenu('dispose');
        $('#tactics-menu').metisMenu();
        
        gen_tc_context_menu();

      } else {
        $("li.tc-category[data-this-category-id='" + data.id + "'").find("span.category_name").text(data.name);
      }

    },
    error: function(xhr, status, error) {
      
      // 401: unaurthorised?
      if (xhr.status == "401") {

        // Give them 5 secs to see the message then redirect
        setTimeout(function() {
            window.location.replace("/users/sign_in");
        }, 5000);   
      }
    } // End error
  }); // End ajax
}


function save_tactic(event) {
  $.ajax({
    url: $('#tactic_form').attr('action'), 
    type: $('#tactic_form').attr('method'), 
    data: $('#tactic_form').serialize(),
    success: function(data) {
      
      toastr.success("Item updated successfully");
      
      $("#dialog").modal('hide');

      // update the reference and name in out list
      if(blnTacticNeedsAddingToMenu) {
        newItem = '<li class="tc-tactic" data-category-level="TODO" data-tactic-id="' + data.id + '">' +
                    '<a aria-expanded="false" class="tactic" href="#" style="color:#7496AD;">' +
                      '<i class="bi bi-arrow-bar-right"></i>' +
                      '<span class="tactic_name">' + data.name + '</span>' +
                      '<span class="fa arrow"></span>' +
                    '</a>' +
                  '</li>';
                  
        $("ul.tactics-sub-menu[data-this-category-id='" + data.tactic_category_id + "']").append(newItem); 
        
        blnTacticNeedsAddingToMenu = false;
        
        // Refresh the menu to deal with our newly added item
        $("#tactics-menu").metisMenu('dispose');
        $('#tactics-menu').metisMenu();
        
        gen_tc_context_menu();

      } else {
        if(data.active) {
          $("li.tc-tactic[data-tactic-id='" + data.id + "'").removeClass('tactic_inactive');  
        } else {
          $("li.tc-tactic[data-tactic-id='" + data.id + "'").addClass('tactic_inactive');  
        }
        $("li.tc-tactic[data-tactic-id='" + data.id + "'").find("span.tactic_name").text(data.name);
      }

    },
    error: function(xhr, status, error) {
      
      // 401: unaurthorised?
      if (xhr.status == "401") {

        // Give them 5 secs to see the message then redirect
        setTimeout(function() {
            window.location.replace("/users/sign_in");
        }, 5000);   
      }
    } // End error
  }); // End ajax
}


function fnDeleteTacticCategoryConfirmed(intSelectedTacticCategoryID) {
  
  $.ajax({
    url: '/tactic_categories/' + intSelectedTacticCategoryID, 
    type: 'delete', 
    dataType: 'json',
    tactic_category_id: intSelectedTacticCategoryID,
    success: function(data) {
      
      toastr.success("Item deleted successfully");
      
      $("li.tc-category[data-this-category-id='" + this.tactic_category_id + "']").remove();
      $('#tactics-menu').metisMenu();

    },
    error: function(xhr, status, error) {
      toastr.error("An exception was thrown : " + xhr.responseJSON.error);
      
      // 401: unaurthorised?
      if (xhr.status == "401") {

        // Give them 5 secs to see the message then redirect
        setTimeout(function() {
            window.location.replace("/users/sign_in");
        }, 5000);   
      }
    } // End error
  }); // End ajax
}


function fnDeleteTacticConfirmed(intSelectedTacticID) {
  
  $.ajax({
    url: '/tactics/' + intSelectedTacticID, 
    type: 'delete', 
    dataType: 'json',
    tactic_id: intSelectedTacticID,
    success: function(data) {
      
      toastr.success("Item deleted successfully");
      
      $("li.tc-tactic[data-tactic-id='" + this.tactic_id + "']").remove();
      $('#tactics-menu').metisMenu();

    },
    error: function(xhr, status, error) {
      toastr.error("An exception was thrown : " + xhr.responseJSON.error);
      
      // 401: unaurthorised?
      if (xhr.status == "401") {

        // Give them 5 secs to see the message then redirect
        setTimeout(function() {
            window.location.replace("/users/sign_in");
        }, 5000);   
      }
    } // End error
  }); // End ajax
}


function new_tactic_category(intSelectedTacticCategoryID) { 
  $.ajax({
    url: '/tactic_categories/new', 
    type: 'get', 
    dataType: 'script',
    data: {
      tactic_category_id: intSelectedTacticCategoryID
    },
    success: function(data) {
      blnCategoryNeedsAddingToMenu = true;      
    },
    error: function(xhr, status, error) {
      toastr.error("An exception was thrown deleting the row : " + xhr.responseJSON);
      
      // 401: unaurthorised?
      if (xhr.status == "401") {

        // Give them 5 secs to see the message then redirect
        setTimeout(function() {
            window.location.replace("/users/sign_in");
        }, 5000);   
      }
    } // End error
  }); // End ajax
}


function new_tactic(intSelectedTacticCategoryID) {
  $.ajax({
    url: '/tactics/new', 
    type: 'get', 
    dataType: 'script',
    data: {
      tactic_category_id: intSelectedTacticCategoryID
    },
    success: function(data) {
      blnTacticNeedsAddingToMenu = true;      
    },
    error: function(xhr, status, error) {
      toastr.error("An exception was thrown deleting the row : " + xhr.responseJSON);
      
      // 401: unaurthorised?
      if (xhr.status == "401") {

        // Give them 5 secs to see the message then redirect
        setTimeout(function() {
            window.location.replace("/users/sign_in");
        }, 5000);   
      }
    } // End error
  }); // End ajax
}

